import axios from 'axios'
import router from '@/router'
import store from '@/store'


//const serverURL = 'http://localhost:3000'
const serverURL = 'https://api.backend.digital.stuck-komzet.de'

const config = {
    baseURL: `${serverURL}/v1`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 10000
}
let originalUrl = null

const apiClient = axios.create(config)

function authHeaderInterceptor(config) {
    const token = store.state?.user?.tokens?.access?.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

function accessTokenRefreshInterceptor(error) {
    const status = error.response.status
    const config = error.config

    if (config.url === '/auth/verify-email'){
        return;
    }

    if(config.url !== '/auth/refresh-tokens') {
        originalUrl = config.url
    }
    if (status !== 401) {
        return Promise.reject(error);
    }
    if (config.url === '/auth/login'
        || config.url.startsWith('/auth/reset-password')
        || config.url === '/sso/authTokens') {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    if (config.url === '/auth/refresh-tokens') {
        store.commit('logout')
        if(originalUrl !== '/sso/authCode') {
            router.push({ name: 'ssoLogin'});
        }
        return Promise.reject(error);
    }
    const refreshToken = store.getters.refreshToken ? store.getters.refreshToken : 'invalid_token'
    if(!config._retry) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            const tokens = response?.data
            store.commit('updateTokens', tokens)
            config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
                config._retry = true
                return apiClient.request(config)
        })
    }
}

apiClient.interceptors.request.use((config) => {
    return authHeaderInterceptor(config)
})
apiClient.interceptors.response.use(response => {
    return response
}, error => {
    return accessTokenRefreshInterceptor(error)
})
export default {
    login(email, password) {
        return apiClient.post('/auth/login', { email, password }).then(response => {
            return response.data
        })
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response?.data
        })
    },
    verifyEmail(token){
        let data = {};
        data.token = token;
        // eslint-disable-next-line no-unused-vars
        return apiClient.post('/auth/verify-email', {token}).then(response => {

            return true;
        }).catch(error => {
            console.log(error);
            return false;
        });
    },
    forgotPassword(email){
        let data = {};
        data.email = email;
        return apiClient.post('/auth/forgot-password', data)
    },
    resetPassword(token, password){
        let data = {};
        data.password = password;
        return apiClient.post('/auth/reset-password?token='+token, data)
    },

    // eslint-disable-next-line no-unused-vars
    sendStudyProgress(courseId, chapterId = "", lessonId = "", end = false, start = false){
        let data = {};

        if(chapterId != ""){
            data.chapter = chapterId
        }
        if(lessonId != ""){
            data.lesson = lessonId;
        }
        if(start){
            data.startDate = (new Date()).toISOString();
        }
        if(end){
            data.completionDate = (new Date()).toISOString();
        }
        return apiClient.post("/courses/"+courseId+"/studyProgress", data).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getStudyProgress(courseId){
        return apiClient.get("/courses/"+courseId+"/studyProgress").then(result => {
            //console.log(result);
            return result.data;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getStudyProgressUser(userId){
        return apiClient.get("/users/"+userId+"/studyProgress").then(result => {
            //console.log(result);
            return result.data.results;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    sendEvent(type, value = "", courseId = "", chapterId = "", lessonId = ""){


        let data = {
            "type": type
        };
        if(value != ""){
            data.value = value;
        }
        if(courseId != ""){
            data.courseId = courseId;
        }
        if(chapterId != ""){
            data.chapterId = chapterId
        }
        if(lessonId != ""){
            data.lessonId = lessonId;
        }
        return apiClient.post("/statistics/logs", data).then(result => {
            //console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getUserEvents(userId, type = ""){
        let addParams = "";
        if(type != ""){
            addParams += "&type="+type;
        }
        return apiClient.get(`/statistics/logs/?userId=${userId}&sortBy=date:desc`+addParams).then(result => {
            //console.log(result);
            return result.data;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getTeamMembers(userId) {
        return apiClient.get(`/users/${userId}/teamMembers`).then(result => {
            //console.log("Employees:", result.data)
            return result.data.results
        });
    },
    registerUser(user){
        return apiClient.post("auth/register", user).then(result => {
            return result.data
        })
    },
    getUsers() {
        return apiClient.get("/users?limit=10000").then(result => {
            return result.data.results
        })
    },
    getUser(userId) {
        return apiClient.get(`/users/${userId}`).then(result => {
            //console.log("User:", result.data)
            return result.data
        })
    },
    isEmailTaken(email) {
        return apiClient.get(`/users?email=${email}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    isLoginTaken(loginName) {
        return apiClient.get(`/users?loginName=${loginName}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    isGroupNameTaken(groupName) {
        return apiClient.get(`/groups?name=${groupName}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    saveUser(user) {
        return apiClient.post("/users", user).then(result => {
            return result.data
        })
    },
    updateUser(user) {
        const userId = user.id
        const _user = {...user}
        delete _user.id
        delete _user.assignments
        return apiClient.patch(`/users/${userId}`, _user).then(result => {
            return result.data
        })
    },
    deleteUser(user) {
        return apiClient.delete(`/users/${user.id}`).then(result => {
            return result.data
        })
    },
    getGroup(group) {
        return apiClient.get(`/groups/${group.id}`).then(result => {
            return result.data.results
        })
    },
    getGroups(instance = undefined) {
        return apiClient.get( "/groups?" + (instance ?  "instance=" +  instance : "")).then(result => {
            return result.data.results
        }).catch(error => {
            return Promise.reject(error)
        })
    },
    saveGroup(group) {
        if(!group.id) {
            return apiClient.post("/groups", group).then(result => {
                return result.data
            })
        } else {
            const groupId = group.id
            delete group.id
            return apiClient.patch(`/groups/${groupId}`, group).then(result => {
                return result.data
            })
        }
    },
    deleteGroup(group) {
        return apiClient.delete(`/groups/${group.id}`)
    },
    getMembers(groupId) {
        return apiClient.get(`/groups/${groupId}/members`).then(result => {
            return result.data
        })
    },
    getInstances() {
        return apiClient.get('/instances').then(result => {
            return result.data
        })
    },
    getAuthCode(clientId, redirectUri, scope) {
        return apiClient.post(`/sso/authCode`, {clientId, redirectUri, scope}).then(result => {
            return result.data
        })
    },
    getAuthToken(code, redirectUri) {
        return apiClient.post(`/sso/authTokens`, {"code": code, "redirect_uri": redirectUri, "grant_type": "authorization_code"}).then(result => {
            return result.data
        })
    },
    getTags() {
        return apiClient.get( "/tags").then(result => result.data.results).then(tags => {
            return tags
        })
    },
    getCourses(instance = undefined) {
        return apiClient.get( "/courses?" + (instance ?  "instance=" +  instance : "")).then(result => {
            return result.data.results
        })
    },
    getUserCourses(userId) {
        return apiClient.get( `/users/${userId}/courses`).then(result => {
            return result.data
        })
    },
    getCoursesInfo() {
        return apiClient.get( "/public/courses/info").then(result => {
            return result.data.results
        })
    },
    getDemoCourses() {
        return apiClient.get( "/public/courses/demo").then(result => {
            //console.log(result.data);
            return result.data
        })
    },
    deleteCourse(course) {
        return apiClient.delete(`/courses/${course.id}`)
    },
    saveCourse(course) {
        if(!course.id) {
            return apiClient.post("/courses", course).then(result => {
                return result.data
            })
        } else {
            const _course = { ...course }
            const courseId = course.id
            delete _course.id
            delete _course.createdAt
            delete _course.updatedAt
            return apiClient.patch(`/courses/${courseId}`, _course).then(result => {
                return result.data
            })
        }
    },
    getCourse(course_id) {
        return apiClient.get(`/courses/${course_id}`).then(result => {
            //console.log(result.data);
            return result.data
        })
    },
    getCourseUsers(course_id){
        return apiClient.get(`/courses/${course_id}/assignedUsers`).then(result => {
            //console.log(result.data);
            return result.data.results;
        })
    },
    isCourseTitleTaken(courseTitle) {
        return apiClient.get(`/courses?name=${courseTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },

    upload(file, onUploadProgress, description = "") {
        let formData = new FormData();

        formData.append("courseData", file);
        formData.append("description", description)

        return apiClient.post("/upload/courseData", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
            timeout: 0
        });
    },
    getChapters(tags = undefined) {
        return apiClient.get( "/chapters" + (tags ? "?tags=" + tags  : "")).then(result => {
            //console.log("Chapters:", result.data)
            return result.data.results
        })
    },
    deleteChapter(chapter) {
        return apiClient.delete(`/chapters/${chapter.id}`)
    },
    saveChapter(chapter) {
        if(!chapter.id) {
            return apiClient.post("/chapters", chapter).then(result => {
                return result.data
            })
        } else {
            const _chapter = { ...chapter }
            const chapterId = chapter.id
            delete _chapter.id
            delete _chapter.createdAt
            delete _chapter.updatedAt
            return apiClient.patch(`/chapters/${chapterId}`, _chapter).then(result => {
                return result.data
            })
        }
    },
    getChapter(chapter) {
        return apiClient.get(`/chapters/${chapter.id}`).then(result => {
            return result.data
        })
    },
    isChapterTitleTaken(chapterTitle) {
        return apiClient.get(`/chapters?name=${chapterTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    getFiles() {
        return apiClient.get("/files");
    },
    getLessons(tags = undefined) {
        return apiClient.get( "/lessons" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
}
