import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import axios from "axios";
import {XMLParser} from "fast-xml-parser";

export default {
    inArray: function (needle, haystack){
        for(let i = 0; i < haystack.length; i++){
            let h = haystack[i];
            if(h == needle){
                return true;
            }
        }
        return false;
    },
    randomString: function (length) {
        let text = ""
        let chars = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ"

        for( let i=0; i < length; i++ ) {
            text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
    },
    trimTitle: function(title){
        if(!title)
            return title;
      if(title.startsWith("{")){
         title = title.substring(title.indexOf('}')+1);
      }
      return title;
    },
    formatLocaleDate: function(date, time) {
        if(time){
            return format(parseISO(date), 'dd.MM.yy - HH:mm', {locale: de})
        }
        return format(parseISO(date), 'PP', {locale: de})
    },
    getUserName: function(user){
        if(user){
            if(user.lastName && user.firstName && user.firstName != ""){
                return user.firstName+" "+user.lastName;
            }else{
                return user.loginName;
            }
        }
        return "";
    },
    validatePassword: function(value) {
        if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
            return false;
        }
        return true;
    },
    goToLink(url, popup, locale = ""){
        if(locale != "" && locale != "de"){
            let el = document.createElement('a');
            el.href = url;
            el.pathname = "/"+locale+el.pathname;
            //console.log(el.href);
            url = el.href;
        }

        if(!popup){
            window.location.href = url;
        }else{
            window.open(url);
            this.isAdminUser = true;
        }
    },
    decodeToken: function(token){

        let decoded = Buffer.from(token, 'base64').toString('ascii');
        decoded = this.decodeBase64(token);
        //console.log(decoded);
        return JSON.parse(decoded);
    },
    b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    },
    decodeBase64(base64) {
        const text = atob(base64);
        const length = text.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = text.charCodeAt(i);
        }
        const decoder = new TextDecoder(); // default is utf-8
        return decoder.decode(bytes);
    },
    encodeToken: function(data){
        let json = JSON.stringify(data);
        let encoded = Buffer.from(json).toString('base64');
        //let decoded = Buffer.from(token, 'base64').toString('ascii');
        return encoded;
    },
    userIsAdmin: function(role){
        if(this.inArray(role, ['admin','sale','author','licenceManager'])){
            return true;
        }else{
            return false;
        }
    },
    getFilenameByManifest: async function(scormurl){
        let manifest = await axios.get(scormurl+"/imsmanifest.xml")
            .then(response => {
                return response.data;
                // eslint-disable-next-line no-unused-vars
            }).catch(error => {
                return false;
            });
        let file = "index_lms.html";
        if(manifest){
            const parser = new XMLParser(
                {
                    ignoreAttributes: false
                }
            );
            let manifestObj = parser.parse(manifest);
            let href = manifestObj.manifest.resources.resource["@_href"];
            if(href && href != null && href != ""){
                file = href;
            }
        }
        if(!file.startsWith('/')){
            file = '/'+file;
        }
        return file;
    },
    itemHasContent: function(item, type){
        if(type == 'lesson' && item && item.courseLink && item.courseLink != "") {
            return true;
        }else if(type == 'chapter' && item && item.courseLink && item.courseLink != ""){
            return true;
        }else {
            if (item && item.content && item.content.length > 0 && item.content[0] != "") {
                return true;
            }
        }
        return false;
    },
    getCourseFirstItem: function(course) {
        let item = null;
        if(course.chapters) {
            for (let i = 0; i < course.chapters.length; i++) {
                let itemTmp = course.chapters[i];
                if(itemTmp.chapter == null){
                    continue;
                }
                if (this.itemHasContent(itemTmp.chapter, itemTmp.type)) {
                    //console.log("chapter " + i + " has content");
                    return itemTmp;
                } else if(itemTmp.chapter.lessons){
                    for (let j = 0; j < itemTmp.chapter.lessons.length; j++) {
                        let itemTmp1 = itemTmp.chapter.lessons[j];
                        if (this.itemHasContent(itemTmp1.lesson, itemTmp1.type)) {
                            return itemTmp1;
                        }
                    }
                }
            }
        }
        return item;
    },
    checkCourseCompleted: function(course, completedEvents){
        let completed = true;
        let item = this.getCourseFirstItem(course);
        //console.log(item);
        if(item == null){
            return false;
        }else{
            if(!this.checkItemCompleted(item, completedEvents)){
                return false;
            }
            while(item.nextItem != null){
                item = item.nextItem;
                if(!this.checkItemCompleted(item, completedEvents)){
                    return false;
                }
            }
        }
        return completed;
    },
    checkItemCompleted: function(item, completedEvents){
        if(item.type == "chapter") item = item.chapter;
        if(item.type == "lesson") item = item.lesson;
        for(let i=0; i<completedEvents.length; i++){
            let value = completedEvents[i];
            if(value.completionDate && (value.chapter == item.id || value.lesson == item.id)){
                return true;
            }
        }
        return false;
    },
    getAllTagsFromCourses: function(courses){
        let tags = [];
        courses.forEach((value) => {
            value.tags.forEach((tag) => {
                if (!tags.some(t => t.name === tag.name)) {
                    tags.push(tag);
                }
            });

        });
        return tags;
    },
    addNextPreviousItemsToCourseItems: function(course){
        let items = [];
        course.chapters.forEach((value) => {
            value.type = "chapter";
            value.isGroup = value.chapter.lessons.length > 0;
            items.push(value);
            value.chapter.lessons.forEach((value1) => {
                value1.type = "lesson";
                items.push(value1);
            });
        });
        items.forEach((value, i) => {

            if(i == 0) {
                value.prevItem = null;
                value.nextItem = items[(i + 1)];
            }else if(i == items.length-1){
                value.prevItem = items[(i-1)];
                value.nextItem = null;
            }else{
                value.prevItem = items[(i-1)];
                value.nextItem = items[(i+1)];
            }
        });
        let actualIndex = 0;
        course.chapters.forEach((value) => {
            //console.log(value);
            value.prevItem = items[actualIndex].prevItem;
            value.nextItem = items[actualIndex].nextItem;
            actualIndex++;
            value.chapter.lessons.forEach((value1) => {
                value1.prevItem = items[actualIndex].prevItem;
                value1.nextItem = items[actualIndex].nextItem;
                actualIndex++;
            });
        });
        return course;
    },
    setItemsCompleted(course, completedEvents){
        let item = this.getCourseFirstItem(course);
        if(item != null){
            if(this.checkItemCompleted(item, completedEvents)){
                item.complete = true;
            }else{
                item.complete = false;
            }
            while(item.nextItem != null){
                item = item.nextItem;
                if(this.checkItemCompleted(item, completedEvents)){
                    item.complete = true;
                }else{
                    item.complete = false;
                }
            }
        }
    },
}
