<template>
  <div>
    <v-card  v-if="showPassword" width="400px" class="mt-5 mx-auto">
      <v-card-title>{{$t('New Password')}}</v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
              :label="$t('password')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password"
              tabindex="3"
          />
          {{$t("password_requirements")}}
          <v-text-field
              :label="$t('password repeat')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="passwordConfirmation"
              tabindex="4"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="saveForm"
            tabindex="5"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <my-snack-bar ref="mySnackbar"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import shared from "@/services/shared";
import MySnackBar from "@/components/MySnackBar";

export default {
  name: "ResetPassword",
  components: {MySnackBar},
  data() {
    return {
      token: "",
      password: '',
      passwordConfirmation: '',
      showLogin: false,
      showPassword: true
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    this.showPassword = true;
  },
  methods:{
    saveForm() {
      if (this.password.length < 8 || !shared.validatePassword(this.password)) {
        this.$refs.mySnackbar.error(this.$t("password_malformed"))
        return;
      }
      if ((this.password !== this.passwordConfirmation)) {
        this.$refs.mySnackbar.error( this.$t("passwords_dont_match"))
        return;
      }
      APIService.resetPassword(this.token, this.password)
          .then(() => {
            this.$refs.mySnackbar.success(this.$t("The password has been reset successfully"))
            this.showPassword = false;
          })
          .catch(() => {
            this.$refs.mySnackbar.error(this.$t("Password reset failed"));
          });
    }
  }
}
</script>

<style scoped>

</style>
