<template>
  <v-app>
    <v-card width="400px" class="mt-5 mx-auto">
    </v-card>
  </v-app>
</template>
<script>
import shared from "@/services/shared";
import VueCookie from "vue-cookie";

export default {
  name: 'SSO',
  data () {
    return {
    }
  },
  methods: {
    doLogin() {
      let clientID = "ceikeeghahva2Pho";
      let state = shared.randomString(18);
      let url = process.env.VUE_APP_ADMIN_URL+"/sso/login?client_id="+clientID+"&redirect_uri=https://kurse.stuck-komzet.de/anmeldung-sso/&scope=openid%20email&response_type=code&state="+state;
      VueCookie.set('ssoStateToken', state);
      VueCookie.set('targetLoginRoute', this.$route.query.targetRoute);
      document.location.href = url;
    }
  },
  created() {
    this.doLogin();
  },
  beforeRouteUpdate(to, from, next) {
    this.doLogin();
    next();
  }
}
</script>

<style scoped>

</style>
