<template>
  <div class="forgot-password">
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>{{$t('Reset password')}}</v-card-title>
      <v-card-text>

        <v-form value>

          <v-text-field
              :label="$t('email')"
              prepend-icon="mdi-lock"
              v-model="email"
              tabindex="1"
              style="color:black;"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="saveForm"
            tabindex="5"
        >
          {{$t('Send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <my-snack-bar ref="mySnackbar"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService"
import MySnackBar from "@/components/MySnackBar"

export default {
  name: "ForgotPassword",
  components: {MySnackBar},
  data() {
    return {
      email: ''
    }
  },
  mounted() {
  },
  methods: {
    saveForm() {
      if (this.email !== "") {
        APIService.forgotPassword(this.email)
            .then(() => {
              this.$refs.mySnackbar.success(this.$t("Password reset email has been sent"))
            })
            .catch(() => {
              this.$refs.mySnackbar.error(this.$t("Please enter a valid email"))
            })
      } else {
        this.$refs.mySnackbar.error(this.$t("Please enter a valid email"))
      }
    }
  }
}
</script>

<style scoped>

</style>
